import React from "react"
import Layout from "../components/layout"
import styles from "../components/kontakt.module.css"
export default () => (
  <Layout>
    <div className={styles.container}>
      <div className={styles.column} style={{ paddingRight: "35px" }} />
      <div className={styles.column} style={{ paddingLeft: "35px" }} />
    </div>
  </Layout>
)
